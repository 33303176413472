import { Deserializable } from "./deserializable";

export class Pet implements Deserializable {
    id: string;
    name: string;
    gender: string;
    age: string;
    neutered: string;
    vaccinated: string;
    size: string;
    description1: string;
    //description2: string;
    image: string;
    cartImage: string;
    petCategory: string;
    price: number;
    adopted: boolean;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

}

export const PETS: Pet[] = [

    {
        id: 'schnoodle-puppy-diesel', name: 'Diesel', image: 'assets/images/pets/diesel.jpeg', cartImage: 'assets/img/cartImage/Leyla.jpeg',
        gender: 'Male', age: "8 Weeks", neutered: 'No', vaccinated: 'Yes', size: 'Small', price: 3000,
        petCategory: "Schnoodle Puppy",
        adopted: false,
        description1: "Here comes Diesel, a beautiful Schnoodle puppy ready to put a smile on your face! This angelic pup is vet checked and up to date on shots and wormer. Diesel comes with a 2-years health guarantee provided by us. This bouncy pup is family raised with children and would make an excellent addition to anyone’s family.",
    } as Pet,

    {
        id: 'schnoodle-puppy-gucci', name: 'Gucci', image: 'assets/images/pets/gucci.jpeg', cartImage: 'assets/img/cartImage/hercules.jpeg',
        gender: 'Male', age: "11 Weeks", neutered: 'No', vaccinated: 'Yes', size: 'Small', price: 3000,
        petCategory: "Schnoodle Puppy",
        adopted: false,
        description1: "This sweet little Schnoodle Pup is Gucci, a family raised Schnoodle puppy! He has the most adorable scrunchy face and he can’t wait to give you lots of love and puppy kisses. Gucci is vet checked, up to date on shots and dewormer, plus we provide a 2 Years health guarantee. This cuties' parents live on the premises.",
    } as Pet,

    {
        id: 'schnoodle-puppy-huxley', name: 'Huxley', image: 'assets/images/pets/huxley.jpeg', cartImage: 'assets/img/cartImage/peanut.jpeg',
        gender: 'Male', age: "11 Weeks", neutered: 'No', vaccinated: 'Yes', size: 'Small', price: 3000,
        petCategory: "Schnoodle Puppy",
        adopted: true,
        description1: "Check out, Huxley! He is an adorable Schnoodle puppy with a bubbly spirit. This precious little guy loves to play and is ready to join in all of your family fun. Huxley is vet checked and up to date on shots and wormer. He is registered with the AKC, plus comes with a health guarantee provided by us. ",
    } as Pet,
    {
        id: 'schnoodle-puppy-junior', name: 'Junior', image: 'assets/images/pets/junior.jpeg', cartImage: 'assets/img/cartImage/toby.jpeg',
        gender: 'Male', age: "10 Weeks", neutered: 'No', vaccinated: 'Yes', size: 'Small', price: 3000,
        petCategory: "Schnoodle Puppy",
        adopted: true,
        description1: "Junior is a precious little Schnoodle puppy who is just as cute as can be! This social little guy is vet checked and up to date on shots and wormer. He is Potty Trained, plus comes with a health guarantee provided by us. Junior is family raised with children and he loves to cuddle and play. ",
    } as Pet,
    {
        id: 'schnoodle-puppy-jeter', name: 'jeter', image: 'assets/images/pets/jeter.jpeg', cartImage: 'assets/img/cartImage/toby.jpeg',
        gender: 'Male', age: "11 Weeks", neutered: 'No', vaccinated: 'Yes', size: 'Small', price: 3000,
        petCategory: "Schnoodle Puppy",
        adopted: false,
        description1: "Here comes Jeter, a beautiful Schnoodle puppy ready to put a smile on your face! This angelic pup is vet checked and up to date on shots and wormer. Jeter comes with a 2-years health guarantee provided by us. This bouncy pup is family raised with children and would make an excellent addition to anyone’s family.",
    } as Pet,

    {
        id: 'schnoodle-puppy-leyla', name: 'Leyla', image: 'assets/images/pets/leyla.jpeg', cartImage: 'assets/img/cartImage/willow.jpeg',
        gender: 'Female', age: "8 Weeks", neutered: 'No', vaccinated: 'Yes', size: 'Small', price: 3000,
        petCategory: "Schnoodle Puppy",
        adopted: false,
        description1: "This sweet little pup is Leyla, a family raised Schnoodle puppy! She has the most adorable scrunchy face and she can’t wait to give you lots of love and puppy kisses. Leyla is vet checked, up to date on shots and dewormer, plus we provide a 2 Years health guarantee. This cuties' parents live on the premises.",
    } as Pet,

    {
        id: 'schnoodle-puppy-molly', name: 'Molly', image: 'assets/images/pets/molly.jpeg', cartImage: 'assets/img/cartImage/willow.jpeg',
        gender: 'Female', age: "8 Weeks", neutered: 'No', vaccinated: 'Yes', size: 'Small', price: 3000,
        petCategory: "Schnoodle Puppy",
        adopted: false,
        description1: "Charming puppy Molly is now ready to meet their new loving family. She is current on all shots, potty trained, and has an excellent pedigree. We are looking for the perfect home for her where she will be shown all love and care. Parents are both health tested. We expect nothing less from these little one. She cme with a 2 years health guarantee. ",
    } as Pet,

    {
        id: 'schnoodle-puppy-pearl', name: 'Pearl', image: 'assets/images/pets/pearl.jpeg', cartImage: 'assets/img/cartImage/xena.jpeg',
        gender: 'Female', age: "9 Weeks", neutered: 'No', vaccinated: 'Yes', size: 'Small', price: 3000,
        petCategory: "Schnoodle Puppy",
        adopted: false,
        description1: "This sweet little pup is Pearl, a family raised Schnoodle puppy! She has the most adorable scrunchy face and she can’t wait to give you lots of love and puppy kisses. Pearl is vet checked, up to date on shots and dewormer, plus we provide a 2 years genetic health guarantee. This cuties' parents live on the premises.",
    } as Pet,
    {
        id: 'schnoodle-puppy-ruby', name: 'Ruby', image: 'assets/images/pets/ruby.jpeg', cartImage: 'assets/img/cartImage/Leo.jpg',
        gender: 'Female', age: "8 Weeks", neutered: 'No', vaccinated: 'Yes', size: 'Small', price: 3000,
        petCategory: "Schnoodle Puppy",
        adopted: true,
        description1: "Ruby is a precious little Schnoodle puppy who is just as cute as she can be! This social little pup is vet checked and up to date on shots and wormer. She is Potty Trained, plus comes with a health guarantee provided by us. Ruby is family raised with children and she loves to cuddle and play. ",
    } as Pet,
    {
        id: 'schnoodle-puppy-walden', name: 'Walden', image: 'assets/images/pets/walden.jpeg', cartImage: 'assets/img/cartImage/toby.jpeg',
        gender: 'Male', age: "11 Weeks", neutered: 'No', vaccinated: 'Yes', size: 'Small', price: 3000,
        petCategory: "Schnoodle Puppy",
        adopted: false,
        description1: "Here comes Walden, a beautiful Schnoodle puppy ready to put a smile on your face! This angelic pup is vet checked and up to date on shots and wormer. Walden comes with a 2-years health guarantee provided by us. This bouncy pup is family raised with children and would make an excellent addition to anyone’s family.",
    } as Pet,
    {
        id: 'schnoodle-puppy-pixel', name: 'Pixel', image: 'assets/images/pets/pixel.jpeg', cartImage: 'assets/img/cartImage/hercules.jpeg',
        gender: 'Male', age: "10 Weeks", neutered: 'No', vaccinated: 'Yes', size: 'Small', price: 3000,
        petCategory: "Schnoodle Puppy",
        adopted: false,
        description1: "This sweet little Schnoodle Pup is Pixel, a family raised Schnoodle puppy! He has the most adorable scrunchy face and he can’t wait to give you lots of love and puppy kisses. Pixel is vet checked, up to date on shots and dewormer, plus we provide a 2 Years health guarantee. This cuties' parents live on the premises.",
    } as Pet,
];
  