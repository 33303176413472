import { Component, OnInit } from '@angular/core';
import { Pet } from '../../models/pets';
import { PetService } from '../../services/pets.service';
import { Contact } from '../../models/api/contact';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiService } from '../../services/api.service';
import Swal from 'sweetalert2'
import { SEOService } from '../../services/seo.service';
import { MetaTag } from '../../models/metaTag';
import { PropertyTag } from '../../models/api/propertyTag.model';
import { Seo } from '../../models/api/seo.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  pets: Pet[] = new Array();
  contact: Contact = new Contact();
  isLoading = false;
  seo = new Seo()
  tags = new Array()
  date: Date
  properties = new Array()
  schema;
  navigationSchema;
  buttonMsg = "Send Message";
  private pathToAssets: string = "../../assets/";


  constructor(
    private productService: PetService, 
    private apiService: ApiService,
    private seoService: SEOService) {
      var now = new Date(), y = now.getFullYear(), m = now.getMonth();
      this.date = new Date(y, m + 1, 0);
  }

  ngOnInit() {
    this.pets = this.productService.findAll();
  }

  submit() {
    this.buttonMsg = "Loading..."
    this.apiService.contact(this.contact)
    .subscribe((data: any) => {
      this.isLoading = true;
      Swal({
        title: 'Thanks!',
        text: 'Thank you! Your message has been received. We will contact you as soon as possible',
        type: 'success',
        confirmButtonText: 'Ok',
      })
      this.contact = new Contact();
      this.isLoading = false;
      this.buttonMsg = "Send Message";
    },
      (err: HttpErrorResponse) => {
        this.isLoading = false;
        Swal({
          title: 'Error!',
          text: 'Unable to send message. Make sure you are connected to the internet',
          type: 'warning',
          confirmButtonText: 'Ok got it!',
        })
        this.buttonMsg = "Send Message";
      });

  }
}
